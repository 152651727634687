<template>
  <div>
    
    <template v-if="hasPaid">
        <div class="cover cover--small" :style="'background-image: url(' + require('@/assets/cover-donate.jpg') + ')'">
            <h1 class="cover__title">You have the power<br>to make a difference.</h1>
        </div>
        <div class="section wrapper">
            <h1 class="title title--spaced">Thank you for your contribution. </h1>       
            <p class="text">Your support is an essential part of our campaign to fight back against politicians who over tax, over spend and keep forgetting who they were elected to serve (THE PEOPLE).</p>
            <p class="text">Thank you for supporting Aaron’s campaign.</p>
            <p class="text">Sincerely,</p>
            <p class="text">The Aaron Gunn Team</p>
        </div>
    </template>

    <template v-else>
        <div class="cover cover--small" :style="'background-image: url(' + require('@/assets/cover-donate.jpg') + ')'">
            <h1 class="cover__title">You have the power<br>to make a difference.</h1>
        </div>
        <div class="section wrapper"> 
            <div class="box">
                <h1 class="title title--spaced">Help Aaron take on the Trudeau-NDP coalition by making a contribution to his campaign today!</h1>
                <p class="text">Contributions can be up to a maximum of $1,700 per person and are not tax deductible (sorry!).</p>
                <app-form action="paypal.php" source="donation" submit-text="Proceed with Payment" :quick-form="true" :show-address="true">
                    <!-- <div class="form__item">
                        <h1 class="title title--small">Choose if you’d like a recurring contribution*</h1>
                        <div class="segment">
                            <input class="segment__input" type="radio" name="periodicity" value="one" id="one" @change="changePeriodicity" checked /><label class="segment__label" for="one">One Time</label>
                            <input class="segment__input" type="radio" name="periodicity" value="monthly" id="monthly" @change="changePeriodicity" /><label class="segment__label" for="monthly">Monthly</label>
                        </div>
                    </div> -->
                    <input type="hidden" name="periodicity" value="one"/>
                    <div class="form__item">
                        <h1 class="title title--small">Amount you’d like to contribute*</h1>
                        <div class="segment">
                            <input class="segment__input" type="radio" name="amount" value="10" id="10" @change="handleChange" checked /><label class="segment__label" for="10">$10</label>
                            <input class="segment__input" type="radio" name="amount" value="50" id="50" @change="handleChange" /><label class="segment__label" for="50">$50</label>
                            <input class="segment__input" type="radio" name="amount" value="100" id="100" @change="handleChange"/><label class="segment__label" for="100">$100</label>
                            <input class="segment__input" type="radio" name="amount" value="250" id="250" @change="handleChange"/><label class="segment__label" for="250">$250</label>
                            <input class="segment__input" type="radio" name="amount" value="1000" id="1000" @change="handleChange"/><label class="segment__label" for="1000">$1000</label>                            
                            <input class="segment__input" type="radio" name="amount" value="other" id="other" @change="handleChange" /><label class="segment__label" for="other">Other</label>
                        </div>
                    </div>
                    <div class="form__item" id="other_amount" style="margin-bottom: 30px;"><label>Enter amount*</label><input name="other_amount" type="number" max="100000" autocomplete="off"/></div>
                </app-form>

                <ul class="qna">
                    <li class="qna__item">
                        <h1 class="qna__question">→ Can I send a donation by e-transfer?</h1>
                        <p class="text">Yes! A donation by e-transfer can also be sent to donations@aarongunn.ca. Please include your physical address in memo field when making an “e-transfer”. </p>
                    </li>
                </ul>
            </div>
        </div>
    </template>
  </div>
</template>

<script>
import AppForm from '@/components/utils/AppForm.vue'
export default {
    components: {
        AppForm
    },
    mounted() {
        this.handleChange();
    },
    data() {
        return {
            periodicity: 'one'
        }
    },
    computed: {
        hasPaid () {
            return this.$router.currentRoute.query.hasOwnProperty('paid');
        }
    },
    methods: {
        handleChange(event) {
            if(document.getElementById("other").checked || (event && event.target.value == 'other')) {
                document.getElementById('other_amount').style.display = '';
                document.querySelector("input[name=other_amount]").required = true;
                document.querySelector("input[name=other_amount]").pattern = "\\d+(\\.\\d{2})?";
            }
            else {
                document.getElementById('other_amount').style.display = 'none';
                document.querySelector("input[name=other_amount]").removeAttribute("pattern"); 
            }
        },

        changePeriodicity(event) {
            this.periodicity = event.target.value;
        },
    }

}
</script>

<style scoped>
.segment { margin: 0 0 20px; display: flex; user-select: none; border-radius: 3px; color: white; font-weight: 600; }
.segment__input { display: none; } 
.segment__input:checked + .segment__label { color: #fff; background: #062573; }
.segment__label { text-align: center; cursor: pointer; padding: 8px 30px; background: #AAB7DA; } 
.segment__label:not(:last-child) { border-right: 1px solid #fff; }
.segment__label:first-of-type { border-radius: 3px 0 0 3px; }
.segment__label:last-of-type { border-radius: 0 3px 3px 0; } 

.other { margin-top: 60px; }
.other__item { display: flex; flex-direction: column; background: rgba(195, 213, 255, 0.5); border-radius: 20px; overflow: hidden; }
.other__item--politics-explained { margin-right: 30px; }
.other__title { padding: 30px 0; font-size: 3rem; color: white; text-transform: uppercase; }
.other__icon { max-width: 50%; height: 175px; margin: 30px auto; }
.other__item--politics-explained .other__title { background: #1F4FC5; }
.other__item--sponsor .other__title { background: #071F5A; }

@media (max-width: 1100px) {
   .segment { width: 100%; }
   .segment__label { padding-left:0; padding-right:0; flex: 1; }
   .other__item { margin-bottom: 20px; }
   .other__item--politics-explained { margin-right: 0; }
}

@media (max-width: 768px) {

}

@media (max-width: 526px) {

}

</style>
